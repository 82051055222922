import React, { useState, useRef, useEffect } from "react";
import i18n from "../../i18n";

import imgFlagRu from "../../images/icons/flag/ru.svg";
import imgFlagEs from "../../images/icons/flag/es.svg";
import imgFlagGer from "../../images/icons/flag/ger.svg";
import imgFlagIt from "../../images/icons/flag/it.svg";
import imgFlagUsa from "../../images/icons/flag/usa.svg";
import imgArrowUp from "../../images/icons/iconamoon_arrow-up-2-light.svg";
import { useTranslation } from "react-i18next";
import { getCHPYPapamByName, getLang } from "../../utils/utils";
import { useLocalStorage } from "usehooks-ts";

export default function ChoseLang() {
  const chooseLangRef = useRef(null);
  // const langs = ["English", "Russian", "Italiano", "Deutch", "Espanol"];
  const langs = ["en", "ru","es"];
  // const langs = i18n.languages;
  // console.log(Object.keys(i18n.store.data));

  const chosenLang = getLang();
  const [selectLang, setSelectLang] = useState(chosenLang);
  const [, setHookLCS] = useLocalStorage("lang", chosenLang);

  const getSelect = (lang) => (lang === chosenLang ? "select" : "");
  const { t } = useTranslation();

  useEffect(() => {
    // FIXME дублируется с хуком, нужно установить либу и сделать красиво без дубликатов
    // и пробросов
    const languages = [
      { title: "English", flag: "us", code: "en", country: "US" },
      { title: "Русский", flag: "ru", code: "ru", country: "RU" },
      { title: "Español", flag: "es", code: "es", country: "ES" },
    ];
    const paramLang = getCHPYPapamByName("lang");

    if (paramLang && languages.length) {
      const currentLang = languages.find((el) => {
        return `${el.code}_${el.country}` === paramLang;
      });

      if (currentLang) {
        const code = currentLang.code;
        i18n.changeLanguage(code);
        localStorage.setItem("lang", code);
        setHookLCS(code);
        setSelectLang(code);
      }
    }
  }, []);

  const flagToLang = {
    ru: imgFlagRu,
    en: imgFlagUsa,
    es: imgFlagEs,
    Deutch: imgFlagGer,
    Italiano: imgFlagIt,
  };

  const codeByTitle = {
    ru: "Русский",
    en: "English",
    es: "Español",
  };

  const catLangByLang = {
    ru: "Ru",
    en: "En",
    es: "Es",
    Deutch: "De",
    Italiano: "It",
  };

  const cookieLang = {
    ru: "ru_RU",
    en: "en_US",
    es: "es_ES",
    Deutch: "de_DE",
    Italiano: "it_IT",
  };

  const changeLang = (lang) => {
    document.cookie = encodeURIComponent("language") + "=" + encodeURIComponent(cookieLang[lang]);
    i18n.changeLanguage(lang);
    setSelectLang(lang);
    localStorage.setItem("lang", lang);
    setHookLCS(lang);
  };

  const currentImg = flagToLang[selectLang];

  return (
    <div className="chose-lang" onMouseOver={() => chooseLangRef.current.classList.remove("close")}>
      <span className="lang-item main select">
        <img src={currentImg} alt="flag" />
        <span>
          {catLangByLang[selectLang]}
          <img src={imgArrowUp} alt="" className="arrow" />
        </span>
      </span>
      <ul ref={chooseLangRef}>
        {langs.map((el, indx) => (
          <li
            key={indx}
            onClick={() => {
              changeLang(el);
              chooseLangRef.current.classList.add("close");
            }}
            className={`lang-item ${getSelect(el)}`}
          >
            <img src={flagToLang[el]} alt="flag" />
            <span>{t(codeByTitle[el])}</span>
          </li>
        ))}
      </ul>
      <div className="chose-lang__plug" />
    </div>
  );
}
