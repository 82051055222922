import { MODEL } from "../components/common/consts";

export const sleep = async (ms) => new Promise((rs, rj) => setTimeout(rs, ms));

export const getLang = () => {
  try {
    const configLang = window.startConfig?.language?.code;
    const serverLang = configLang?.substring(0, 2)?.toLowerCase?.();
    if (serverLang) return serverLang;

    const lang = JSON.parse(localStorage.getItem("lang"));
    if (!lang) throw new Error("no lang");
    return JSON.parse(localStorage.getItem("lang"));
  } catch {
    const langBrowserFull = navigator.language || navigator?.userLanguage;
    const langMiniBrowser = langBrowserFull.substring(0, 2)?.toLowerCase();
    const langBrowser = langMiniBrowser || "en";
    return langBrowser;
  }
};

// setCookie - установка в куки
export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString() + "; path=/";
  }
  // document.cookie = name + "=" + (value || "") + expires + "; path=/";
  document.cookie = name + "=" + (value || "") + expires;
}

export const getFormInputs = (children) => {
  const data = {};

  for (let i = 0; i < children.length; i++) {
    const item = children.item(i);
    if (item.type === "radio") {
      if (item.checked) data[item.name] = item.value;
      continue;
    }
    data[item.name] = item.value;
  }
  return data;
};

export const redirectToLk = (type) => {
  window.location.href = type === MODEL ? "/webcam/model/chat" : "/webcam/studio/manager";
};

// getCHPYPapamByName -  парсинг параметра по измени из  ЧПУ
export function getCHPYPapamByName(name) {
  const pathname = window.location.pathname;
  return pathname.split(name + ":")?.[1]?.split("/")?.[0];
}
